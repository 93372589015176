import { createWebHistory, createRouter } from "vue-router";

import Home from "../views/Home.vue";
import Blog from "../views/Blog.vue";
import GabungMitra from "../views/GabungMitra.vue";
import Faq from "../views/Faq.vue";

const routes = [
    {
        path:"/",
        name:"Beranda",
        component: Home
    },
    {
        path:"/blog",
        name:"Blog",
        component: Blog
    },
    {
        path:"/gabungmitra",
        name:"Gabung Mitra",
        component: GabungMitra
    },
    {
        path:"/faq",
        name:"FAQ",
        component: Faq
    },
];

const router = createRouter({
    history:createWebHistory(),
    routes
});

export default router;