<template>
  <!--  Hero Section  -->
  <Hero />
  <!-- End Hero -->

  <main id="main">
    <!--  Services Section  -->
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Layanan</h2>
        </div>

        <div class="row">
          <div
            class="col-xl-4 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div class="icon-box">
              <div class="icon">
                <img width="30px" src="assets/icons/yo_rumah.png" alt="" />
              </div>
              <h4><a href="">Yo Rumah</a></h4>
              <p>
                Layanan Yohom yang memberikan solusi kerusakan rumah anda
                seperti atap, plumbing dan lainya
              </p>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="icon-box">
              <div class="icon">
                <img width="30px" src="assets/icons/yo_elektro.png" alt="" />
              </div>
              <h4><a href="">Yo Elektro</a></h4>
              <p>Solusi Perbaikan barang Elektronik kesayangan anda</p>
            </div>
          </div>

          <div
            class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div class="icon-box">
              <div class="icon">
                <img width="30px" src="assets/icons/yo_urgent.png" alt="" />
              </div>
              <h4><a href="">Yo Urgent</a></h4>
              <p>
                Dalam Kondisi darurat, kami siap memberikan layanan untuk anda
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Services Section -->

    <!--  Why Us Section  -->
    <section id="why-us" class="why-us section-bg">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row">
          <div
            class="
              col-lg-7
              d-flex
              flex-column
              justify-content-center
              align-items-stretch
              order-2 order-lg-1
            "
          >
            <div class="content">
              <h3>
                Kenapa
                <strong>Harus Memilih Yohom?</strong>
                <br />
              </h3>
            </div>

            <div class="accordion-list">
              <ul>
                <li>
                  <a
                    data-bs-toggle="collapse"
                    class="collapse"
                    data-bs-target="#accordion-list-1"
                    ><span>01</span> Solusi Permasalahan Anda
                    <i class="bx bx-chevron-down icon-show"></i
                    ><i class="bx bx-chevron-up icon-close"></i
                  ></a>
                  <div
                    id="accordion-list-1"
                    class="collapse show"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      Hal pertama yang teringat jika ditanya tentang yohom
                      tentunya adalah <b>Solusi</b>. Yohom merupakan aplikasi
                      yang akan membantu permasalahan keseharian anda seperti
                      kerusakan AC, Atap, Saluran Air dan lainya.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-2"
                    class="collapsed"
                    ><span>02</span> Mudah & Praktis digunakan
                    <i class="bx bx-chevron-down icon-show"></i
                    ><i class="bx bx-chevron-up icon-close"></i
                  ></a>
                  <div
                    id="accordion-list-2"
                    class="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      Aplikasi Yohom merupakan aplikasi yang berbasis pada
                      Smartphone dimana pengguna sudah sangat familiar. Jadi,
                      penggunaan Yohom sangat <b>Mudah dan Praktis</b>.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-3"
                    class="collapsed"
                    ><span>03</span> Pelayanan yang ramah dan Bergaransi
                    <i class="bx bx-chevron-down icon-show"></i
                    ><i class="bx bx-chevron-up icon-close"></i
                  ></a>
                  <div
                    id="accordion-list-3"
                    class="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      Kami sangat menjamin bahwa pelayanan pada Yohom sangatlah
                      baik. selain itu Yohom juga memberikan <b>Garansi</b> pada
                      permasalahan yang anda hadapi.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            class="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style="background-image: url('assets/img/why-us.png')"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </section>
    <!-- End Why Us Section -->

    <!--  Cliens Section  -->
    <section id="cliens" class="contact">
      <div class="container">
        <div class="section-title" style="padding-bottom: 0px">
          <h2>Partner Kami</h2>
        </div>
        <div class="row" data-aos="zoom-in">
          <div
            v-for="partner in partners"
            :key="partner.img"
            class="col-lg-2 col-md-4 col-6"
            style="float: none; margin: auto"
          >
            <img :src="partner.img" :alt="partner.img" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    <!-- End Cliens Section -->
    
  </main>
  <!-- End #main -->
</template>

<script>
import Hero from "../components/templates/Hero.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Hero,
  },
  data() {
    return {
      partners: [
        { img: "assets/img/partner/kemenaker.png" },
        { img: "assets/img/partner/sinarmas.png" },
      ],
      config: {
        headers: { api_key: "yohom_12345" },
      },
      apiUrl: "https://admincp.yohom.co.id/api/",
    };
  },
  mounted() {
    // this.getMedsos();
  },
  methods: {
    getMedsos() {
      axios
        .get(this.apiUrl + "medsos", this.config)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>