<template>
  <main id="main">
    <section class="why-us section-bg">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row">
          <div
            class="
              col-lg-7
              d-flex
              flex-column
              justify-content-center
              align-items-stretch
              order-2 order-lg-1
            "
          >
            <div class="content">
              <h3>
                Kenapa
                <strong>Harus Memilih Yohom?</strong>
                <br />
              </h3>
            </div>

            <div class="accordion-list">
              <ul>
                <li>
                  <a
                    data-bs-toggle="collapse"
                    class="collapse"
                    data-bs-target="#accordion-list-1"
                    ><span>01</span> Solusi Permasalahan Anda
                    <i class="bx bx-chevron-down icon-show"></i
                    ><i class="bx bx-chevron-up icon-close"></i
                  ></a>
                  <div
                    id="accordion-list-1"
                    class="collapse show"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      Hal pertama yang teringat jika ditanya tentang yohom
                      tentunya adalah <b>Solusi</b>. Yohom merupakan aplikasi
                      yang akan membantu permasalahan keseharian anda seperti
                      kerusakan AC, Atap, Saluran Air dan lainya.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-2"
                    class="collapsed"
                    ><span>02</span> Mudah & Praktis digunakan
                    <i class="bx bx-chevron-down icon-show"></i
                    ><i class="bx bx-chevron-up icon-close"></i
                  ></a>
                  <div
                    id="accordion-list-2"
                    class="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      Aplikasi Yohom merupakan aplikasi yang berbasis pada
                      Smartphone dimana pengguna sudah sangat familiar. Jadi,
                      penggunaan Yohom sangat <b>Mudah dan Praktis</b>.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-3"
                    class="collapsed"
                    ><span>03</span> Pelayanan yang ramah dan Bergaransi
                    <i class="bx bx-chevron-down icon-show"></i
                    ><i class="bx bx-chevron-up icon-close"></i
                  ></a>
                  <div
                    id="accordion-list-3"
                    class="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      Kami sangat menjamin bahwa pelayanan pada Yohom sangatlah
                      baik. selain itu Yohom juga memberikan <b>Garansi</b> pada
                      permasalahan yang anda hadapi.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            class="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style="background-image: url('assets/img/why-us.png')"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Blog",
};
</script>