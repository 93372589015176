<template>
  <header id="header" class="fixed-top header-top">
    <div class="container d-flex align-items-center">
      <a class="logo me-auto" href="https://yohom.co.id">
        <img src="assets/img/logos/logo.png" alt="" />
      </a>

      <nav id="navbar" class="navbar">
        <ul>
          <li><router-link to="/" class="nav-link scrollto">Beranda</router-link></li>
          <li><router-link to="/blog" class="nav-link scrollto" >Blog</router-link></li>
          <li><router-link to="/gabungmitra" class="nav-link scrollto" >Gabung Mitra</router-link></li>
          <li><router-link to="/faq" class="nav-link scrollto" >FAQ</router-link></li>
          <li><a class="getstarted scrollto" href="#footer">Unduh Aplikasi</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style>

  .header-top {

    background-color: white ;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0);

  }

</style>