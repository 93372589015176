<template>
  <main id="main">
    <!--  Cliens Section  -->
    <section id="cliens" class="contact">
      <div class="container">
        <div class="section-title" style="padding-bottom: 0px">
          <h2>Pendaftaran Mitra Yohom</h2>
        </div>
        <div class="row" data-aos="zoom-in">
          <div class="form-group col-md-4"></div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label for="nik"
                    >Nomor Induk Kependudukan/NIK <i>(Sesuai KTP)</i>
                  </label>
                </div>
                <div class="col-md-5">
                  <input
                    v-model="form.nik"
                    type="text"
                    placeholder="Masukan NIK Sesuai KTP"
                    class="form-control"
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <label for="nama">Nama Lengkap <i>(Sesuai KTP)</i> </label>
                </div>
                <div class="col-md-5">
                  <input
                    v-model="form.nama"
                    type="text"
                    placeholder="Masukan Nama Lengkap Sesuai KTP"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <label for="nohp">Nomor Handphone </label>
                </div>
                <div class="col-md-5">
                  <input
                    v-model="form.nohp"
                    type="tel"
                    placeholder="Masukan Nomor Handphone Yang Aktif"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <label for="email">Email </label>
                </div>
                <div class="col-md-5">
                  <input
                    v-model="form.email"
                    type="email"
                    placeholder="Masukan Email"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <label for="alamat">Alamat Lengkap</label>
                </div>
                <div class="col-md-6">
                  <textarea
                    v-model="form.alamat"
                    class="form-control"
                    placeholder="Masukan Alamat Lengkap"
                    rows="5"
                    required
                  ></textarea>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <label for="foto_ktp">Foto KTP</label>
                  <p style="font-size: smaller"><i>*Ukuran Maksimal 1MB</i></p>
                </div>
                <div class="col-md-4">
                  <img
                    v-if="previewKtp == null"
                    class="imgKtp"
                    src="@/assets/e_ktp.png"
                  />
                  <img v-else class="imgKtp" :src="previewKtp" />
                  <hr />
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadKtp"
                    class="form-control"
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <label for="foto_ktp">Foto Diri Memegang KTP</label>
                  <p style="font-size: smaller"><i>*Ukuran Maksimal 1MB</i></p>
                </div>
                <div class="col-md-4">
                  <img
                    v-if="previewDiri == null"
                    class="imgKtp"
                    src="@/assets/pegang_ktp.jpg"
                  />
                  <img v-else class="imgKtp" :src="previewDiri" />
                  <hr />
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadDiri"
                    class="form-control"
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <button
                    @click="upload"
                    style="float: right"
                    class="btn btn-danger btn-block"
                  >
                    Daftar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Cliens Section -->
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: "Gabung Mitra",
  data() {
    return {
      form: {
        nik: null,
        nama: null,
        alamat: null,
        nohp: null,
        email: null,
        fotoKtp: null,
        fotoDiri: null,
      },
      config: {
        headers: {
          api_key: "yohom_12345",
        },
      },
      previewKtp: null,
      previewDiri: null,
    };
  },
  components: {},
  methods: {
    showError(msg) {
      return this.$swal({
        title: "Peringatan",
        text: msg,
        icon: "error",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Kembali",
      });
    },
    showSuccess(msg) {
      return this.$swal({
        title: "Informasi",
        text: msg,
        icon: "success",
        confirmButtonColor: "#00d16c",
        confirmButtonText: "Kembali",
      }).then(() => {
        window.location.reload();
      });
    },
    uploadKtp(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      console.log(image);
      if (image.size > 1000000) {
        this.showError("Ukuran Gambar tidak boleh melebihi 1MB");

        this.previewKtp = null;
        return;
      }
      this.form.fotoKtp = image;
      console.log(this.form.fotoKtp);
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewKtp = e.target.result;
      };
    },
    uploadDiri(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      console.log(image);
      if (image.size > 1000000) {
        this.showError("Ukuran Gambar tidak boleh melebihi 1MB");
        this.previewDiri = null;
        return;
      }
      this.form.fotoDiri = image;
      console.log(this.form.fotoDiri);
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewDiri = e.target.result;
      };
    },
    async upload() {
      var form = new FormData();
      form.append("nik", this.form.nik);
      form.append("nama", this.form.nama);
      form.append("no_hp", this.form.nohp);
      form.append("alamat", this.form.alamat);
      form.append("email", this.form.email);
      form.append("foto_ktp", this.form.fotoKtp);
      form.append("foto_diri", this.form.fotoDiri);

      // console.log(form)
      // return;

      await axios
        .post("https://admincp.yohom.co.id/api/mitra/store", form, this.config)
        .then((res) => {
          console.log(res.data);
          if (!res.data) {
            this.showError("Pengajuan Anda sebagai Mitra Gagal di simpan");
            return;
          }
          // this.form.nik = null;
          // this.form.nama = null;
          // this.form.nohp = null;
          // this.form.alamat = null;
          // this.form.email = null;
          // this.form.fotoKtp = null;
          // this.form.fotoDiri = null;
          // this.previewDiri = null;
          // this.previewKtp = null;
          this.showSuccess(
            "Pengajuan Anda sebagai Mitra Berhasil di simpan Mohon Tunggu Informasi dari kami terkait penerimaan mitra."
          );
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
#main {
  padding-top: 50px;
}

.imgKtp {
  width: 350px;
  height: 225px;
  object-fit: cover;
}
</style>